<template>
    <div v-if="list" :class="['rwm-select']" v-click-outside="hideList" @mouseenter="showTooltip=true" @mouseleave="showTooltip=false">
        <div v-if="tooltipText" style="position: relative; display: flex">
            <rwm-tool-tip v-if="showTooltip && tooltipText && disabled"
                          position="center"
                          :text='tooltipText'/>
        </div>
        <rwm-text-field :error="error" :disabled="disabled" :ref="reference" :label="placeholder" readonly is-select-component v-model="selectedValue"
                        @selectClick="switchList">
            <template slot="append-icon">
                <v-icon >{{!isListShowing ? "caret-down" :
                    "caret-up"}}
                </v-icon>
            </template>
            <template slot="list">
                <div :class="['rwm-select__options', {'list-show' : isListShowing}]">
                    <p v-for="option in list" :key="option.value" class="rwm-select__options-option" @click="select(option)">
                        {{option.name}}
                    </p>
                </div>
            </template>
        </rwm-text-field>
    </div>
</template>

<script>
    import RwmTextField from "../RWMTextField/RwmTextField";
    import VIcon from "../icons/VIcon";
    import RwmToolTip from "../RWMToolTip/RwmToolTip";
    export default {
        name: "RwmSelect",
        components: {RwmToolTip, VIcon, RwmTextField},
        directives: {
            clickOutside: {
                bind: function (el, binding, vnode) {
                    el.eventSetDrag = function () {
                        el.setAttribute('data-dragging', 'yes');
                    }
                    el.eventClearDrag = function () {
                        el.removeAttribute('data-dragging');
                    }
                    el.eventOnClick = function (event) {
                        let dragging = el.getAttribute('data-dragging');
                        // Check that the click was outside the el and its children, and wasn't a drag
                        if (!(el === event.target || el.contains(event.target)) && !dragging) {
                            // call method provided in attribute value
                            vnode.context[binding.expression](event);
                        }
                    };
                    document.addEventListener('touchstart', el.eventClearDrag);
                    document.addEventListener('touchmove', el.eventSetDrag);
                    document.addEventListener('click', el.eventOnClick);
                    document.addEventListener('touchend', el.eventOnClick);
                }, unbind: function (el) {
                    document.removeEventListener('touchstart', el.eventClearDrag);
                    document.removeEventListener('touchmove', el.eventSetDrag);
                    document.removeEventListener('click', el.eventOnClick);
                    document.removeEventListener('touchend', el.eventOnClick);
                    el.removeAttribute('data-dragging');
                },
            }
        },
        props: {
            // a list of options objects {name, value}
            list: {
                type: Array,
                required: true
            },
            // show the default value from list
            defaultValue: {
                type: [String, Number],
                default: undefined
            },
            placeholder: {
                type: String,
                default: ""
            },
            reference: {
                type: String,
                default: "selectComponent"
            },
            disabled: {
                type: Boolean,
                default: false
            },
            error: {
                type: Boolean,
                default: false
            },
            tooltipText: {
                type: String,
                default: null
            },
        },
        watch: {
            defaultValue: {
                immediate: true,
                handler() {
                    if (this.defaultValue && this.list) {
                        if (typeof(this.defaultValue) === "number") {
                            this.select(this.list[this.defaultValue-1])
                        } else if (typeof(this.defaultValue) === "string") {
                            this.select(this.list.find(el => el.name === this.defaultValue))
                        }
                    }
                }
            }
        },
        data() {
            return {
                selectedValue: undefined,
                isListShowing: false,
                showTooltip: false,
            }
        },
        methods: {
            select(selected) {
                if (selected) {
                    this.selectedValue = selected.name
                    this.isListShowing = false
                    this.$emit('selected', selected)
                }
            },
            switchList() {
                if (!this.disabled) {
                    if (this.isListShowing) {
                        this.hideList();
                        this.$refs[this.reference].$el.children[0].children[0].children[0].blur()
                    } else {
                        this.isListShowing = true;
                        this.$refs[this.reference].$el.children[0].children[0].children[0].focus()
                    }
                }
            },
            hideList() {
                this.isListShowing = false
            }
        },
    }
</script>

<style scoped lang="scss">
.rwm-select {
    position: relative;
    & .rwm-text-field {

    }
    &__options {
        display: none;
        position: absolute;
        padding: 10px 0 15px 0;
        left: 0;
        right: 0;
        border: 1px solid $RWM-dark-blue;
        border-radius: 0 0 5px 5px;
        border-top: none;
        background-color: $RWM-white;
        z-index: 11;
        &.list-show {
            display: block;
        }
        &-option {
            font-weight: 500;
            font-size: 23px;
            line-height: 31px;
            text-align: left;
            color: $RWM-grey;
            padding: 5px 35px;
            box-sizing: border-box;
            transition: background-color .3s, color .2s;
            &:hover {
                background-color: $RWM-grey-super-light;
                color: $RWM-white;
            }
        }
    }
}
</style>
