<template>
    <div class="row" v-if="selectedBankAccount">
        <div class="col-12">
            <p class="anketa">{{found}}</p>
        </div>
        <div class="col-6">
            <rwm-text-field label="Лицевой счет" v-model="account" readonly />
        </div>
        <div class="col-6">
            <rwm-select v-if="bankAccounts.length > 0" :default-value="selectedBankAccount.name" @selected="setBankAccount" placeholder="Выберите банковские реквизиты" :list="bankAccounts" />
            <p class="anketa bold" v-else>У вас нет банковских счетов</p>
        </div>
    </div>
</template>

<script>
    import RwmTextField from "../../../../../RWMTextField/RwmTextField";
    import RwmSelect from "../../../../../RWMSelect/RwmSelect";
    export default {
        name: "FoundsBlock",
        components: {RwmSelect, RwmTextField},
        props: {
            account: String,
            found: String,
            company:String,
            bankAccounts: Array,
            selectedBankAccount: Object,
        },
        methods: {
            setBankAccount(val) {
                this.$emit('selectedBankAccount', val)
            },
        }
    }
</script>
<style scoped lang="scss">

</style>