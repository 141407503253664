<template>
    <div class="form">
        <h1>Заявление об изменении данных анкеты зарегистрированного физического лица</h1>
        <div class="form__body">
            <h3>У Вас есть открытые лицевые счета в фондах:</h3>
            <founds-block
                    v-for="(found, index) in founds"
                    :key="`${index}-found`"
                    :account="found.ls_number"
                    :company="found.company"
                    :found="found.fond_name"
                    :selectedBankAccount="bankAccounts.find(el => el.id === found.bank_account_id) || null"
                    :bank-accounts="bankAccounts"
                    @selectedBankAccount="setBankAccount(found, ...arguments)"
            />
            <h3>Пожалуйста, перед отправкой документов внимательно проверяйте все данные</h3>
            <client-block :mname="user.userName.mname" :name="user.userName.name" :sname="user.userName.sname"/>
            <passport-block :department="user.passport.department" :issue="user.passport.issue"
                            :issue-date="user.passport.issueDate"
                            :number="user.passport.number"/>
            <address-block
                    :actual-address="user.actualAddress"
                    :birthday="user.birthday"
                    :birthplace="user.birthplace"
                    :email="user.email"
                    :inn="user.inn"
                    :phone="user.phone"
                    :registration-address="user.registrationAddress"
                    :snils="user.snils.number"
            />
            <rwm-checkbox
                    v-model="_agreement"
                    label="Даю <a href='/client/v1/files/get?type=100' target='_blank'>согласие на обработку персональных данных</a>"
            />
            <h3>Прошу изменить данные в соответствии с Анкетой зарегистрированного лица</h3>
            <p class="anketa dark">
                Уведомление об операции/ об отказе в проведении операции прошу направить:
            </p>
            <div class="mailing">
                <rwm-checkbox
                        v-for="(by, index) in mailing"
                        :key="`${index}-mailing`"
                        v-model="mail[by.value]"
                        :label="by.title"
                        disabled
                />
            </div>
            <div class="row">
                <div class="col-3">
                    <rwm-button width="100%" @click="submit()" :disabled="!readyToSubmit">Подписать</rwm-button>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <rwm-sms-checking
                            v-model="_timer"
                            :time="60"
                            field-width="150"
                            @sendAgain="sendAgain()"
                            @verified="codeIsVerified"
                            :isShow="showChecking"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
     import FoundsBlock from "./blocks/FoundsBlock";
     import RwmCheckbox from "../../../../RWMCheckbox/RwmCheckbox";
     import RwmButton from "../../../../RWMButton/RwmButton";
     import RwmSmsChecking from "../../../../RWMSMSChecking/RwmSmsChecking";
     import ClientBlock from "./blocks/ClientBlock";
     import PassportBlock from "./blocks/PassportBlock";
     import AddressBlock from "./blocks/AddressBlock";
    export default {
        name: "ChangePersonalData",
        components: {
            AddressBlock,
            PassportBlock,
            ClientBlock, RwmSmsChecking, RwmButton, RwmCheckbox, FoundsBlock},
        props: {
            user: Object,
            founds: Array,
            timer: Boolean,
            showChecking: Boolean,
            bankAccounts: Array,
            agreement: Boolean
        },
        computed: {
            _agreement: {
                get() {
                    return this.agreement
                },
                set(val) {
                    this.$emit('update', val)
                }
            },
            currentDate() {
                return new Date()
            },
            readyToSubmit() {
                return this.timer ? !this.timer : this.agreement
            },
            _timer: {
                get() {
                    return this.timer
                },
                set(val) {
                    this.$emit('timer', val)
                }
            }
        },
        data() {
            return {
                mail: {
                    byPost: false,
                    byCompany: true,
                    byAgency: false,
                    personal: false,
                },
                mailing: [
                    {value: "byPost", title: "Почтовым отправлением"},
                    {value: "byCompany", title: "В Управляющую компанию"},
                    {value: "byAgency", title: "Агенту по выдаче, погашению и обмену инвестиционных паев"},
                    {value: "personal", title: "Выдать лично на руки"}
                    ]
            }
        },
        methods: {
            submit() {
                this.$emit('onSubmit')
            },
            setBankAccount(fund, bankAccount) {
                let changeAccount = {...fund}
                if (changeAccount.bank_account_id !== bankAccount.id) {
                    changeAccount.bank_account_id = bankAccount.id
                    this.$emit('selectedBankAccount', changeAccount)
                }
            },
            codeIsVerified(code) {
                let form = {}
                this.$emit('verified', {code: code, form: form})
            },
            sendAgain() {
                this.$emit('sendAgain')
            },
        }
    }
</script>

<style scoped lang="scss">
    .form {
        text-align: left;

        & h1 {
            margin-bottom: 32px;
        }

        &__body {
            display: flex;
            flex-direction: column;
            row-gap: 32px;
            & .mailing {
                display: flex;
                flex-direction: column;
                row-gap: 8px;
            }
            & .client-block:after {
                content: none;
            }
        }
    }
    .dark {
        color: $RWM-dark;
    }
</style>