<template>
  <change-personal-data
    :user="getUSER"
    :founds="lsList"
    :bank-accounts="getBANK_ACCOUNTS"
    :timer="timer"
    :show-checking="showChecking"
    :agreement="agreement"
    @onSubmit="onSubmit"
    @sendAgain="onSubmit"
    @timer="setTimer"
    @verified="onVerififed"
    @update="onUpdate"
    @selectedBankAccount="setNewBankAccountToFund"
  />
</template>

<script>
import ChangePersonalData from "../../../../components/pages/cabinet/documents/forms/ChangePersonalData";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import RWMSendingDocumentsMessageMixin from "../../../../components/mixins/RWMSendingDocumentsMessageMixin";

const model = {
  bank_details: null, //привязка к счету
  is_agree: null, //Даю согласие на обработку персональных данных
  send_notification_by_post: null, //направить уведомление почтовым отправлением
  send_notification_to_management_company: true, //направить уведомление в управляющую компанию
  send_notification_to_agent: null, //направить уведомление агенту
  send_notification_personally: null, //выдать лично в руки
};
export default {
  name: "FormChangePersonalData",
  components: { ChangePersonalData },
  mixins: [RWMSendingDocumentsMessageMixin],
  computed: {
    ...mapGetters("errorModule", ["getERROR"]),
    ...mapGetters("AuthModule", ["getUSER"]),
    ...mapGetters("bankAccModule", ["getBANK_ACCOUNTS"]),
    ...mapGetters("DocumentsModule", ["getDRAFT", "getLS_LIST"]),
    agreement() {
      return this.getDRAFT ? this.getDRAFT.data.is_agree : null;
    },
    lsList: {
      get() {
        return this.getLS_LIST.filter(e => !!e.doc_account_open_id);
      },
      set(val) {
        this.SET_LS_LIST(val);
      },
    },
  },
  data() {
    return {
      timer: false,
      showChecking: false,
      founds: [
        {
          account: "1234567890",
          found: "«РВМ Мичуринский» 1",
          company:
            "Закрытое Акционерное Общество Управляющая компания «РВМ Капитал»",
          selectedBankAccount: "1",
        },
        {
          account: "1234567891",
          found: "«РВМ Мичуринский» 2",
          company:
            "Закрытое Акционерное Общество Управляющая компания «РВМ Капитал»",
          selectedBankAccount: "123323sdsd",
        },
      ],
    };
  },
  methods: {
    ...mapActions("DocumentsModule", [
      "GET_DOCUMENTS",
      "CREATE_DOCUMENT",
      "UPDATE_DOCUMENT",
      "GET_LS_LIST",
      "SET_LS_LIST",
    ]),
    ...mapActions("smsModule", ["REQUEST_SMS", "CONFIRM_SMS"]),
    ...mapActions("bankAccModule", ["GET_BANK_ACCOUNTS"]),
    setTimer(val) {
      this.timer = val;
    },
    onSubmit() {
      this.REQUEST_SMS({ phone: this.getUSER.phone, doc_id: this.getDRAFT ? this.getDRAFT.id : null, action: 'document_sign'}).then((success) => {
        if (success) {
          this.timer = true;
          this.showChecking = true;
        } else this.getERROR ? this.$toast.error(this.getERROR) : "";
      });
    },
    onVerififed(data) {
      this.CONFIRM_SMS({ phone: this.getUSER.phone, key: data.code }).then(
        (success) => {
          if (success) {
            // send data here
            let data = { ...this.getDRAFT.data };
            data.date_sign = moment(new Date()).format("X");
            data.signed = true;
            data.open_personal_accounts = this.lsList;
            this.UPDATE_DOCUMENT({ id: this.getDRAFT.id, data: data });
            this.show_sending_documents_message();
            setTimeout(() => {
              if (typeof this.$route.query.to !== "undefined")
              {
                const id = this.$route.query.to;
                if (id == "")
                  this.$router.push(`/cabinet/investment/purchase-of-investment-shares/${id}`);
                else
                  this.$router.push("/cabinet/documents?next");
              } else {
                this.$router.push("/cabinet/documents/personal-data-change");
              }
            }, 2000);
          } else {
            this.$toast.error(`${this.getERROR}`);
          }
        }
      );
    },
    onUpdate(value) {
      this.UPDATE_DOCUMENT({ id: this.getDRAFT.id, data: { is_agree: value } });
    },
    setNewBankAccountToFund(ls) {
      // Change selected bank account to fund here
      let index = this.lsList.findIndex((el) => el.ls_number === ls.ls_number);
      this.lsList.splice(index, 1);
      this.lsList.push(ls);
    },
  },
  mounted() {
    this.GET_LS_LIST();
    this.GET_DOCUMENTS("appl-change_pers_data").then((resp) => {
      if (resp) {
        if (!this.getDRAFT) {
          this.CREATE_DOCUMENT({
            alias: "appl-change_pers_data",
            data: model,
          }).then((response) =>
            !response
              ? this.$toast.error(`${this.getERROR}`)
              : this.GET_DOCUMENTS("appl-change_pers_data")
          );
        }
      }
    });
    this.GET_BANK_ACCOUNTS();
  },
};
</script>

<style scoped lang="scss"></style>
